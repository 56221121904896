import React, { useEffect, useRef } from 'react';
import { QueryResults } from 'react-media';
import { Button, Div, P, Span } from '../common/Elements';
import { MarkdownBlock } from '../common/MarkdownBlock';
import { PlansDetailSection } from './fragments/plans.interface';
import { Size } from '../common/MediaContainer';
interface ModalTooltipProps {
  toggleTooltip: React.Dispatch<React.SetStateAction<string>>;
  section: PlansDetailSection;
  size: Size;
}

const getLeftPosition = (size: Size) => {
  if (size.sm && size.md && !size.xs && !size.lg) {
    return -17;
  } else if (size.sm && !size.xs && !size.md) {
    return -17;
  } else if (size.xs && !size.sm) {
    return -17;
  } else {
    // medium size devices
    return -40;
  }
};
const ModalTooltip: React.FC<ModalTooltipProps> = ({ section, toggleTooltip, size }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (ref.current != null && !ref?.current?.contains(e.target as any)) {
        toggleTooltip('');
      }
    };
    window.addEventListener('click', onClickOutside);
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, []);
  return (
    <Div
      position="absolute"
      style={{
        top: '-24px',
        left: getLeftPosition(size),
        right: '-25px',
        zIndex: 10,
        maxWidth: size.sm && !size.lg ? 400 : 'none',
        transition: 'all',
      }}
    >
      <div ref={ref} className="relative">
        <Button
          backgroundColor="transparent"
          border="none"
          position="absolute"
          style={{ cursor: 'pointer', position: 'absolute', right: '24px', top: '24px' }}
          onClick={() => toggleTooltip('')}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3402 13.6038L8.69571 8.02158L14.278 2.37708L13.567 1.67383L7.98471 7.31858L2.34021 1.73633L1.63721 2.44733L7.28171 8.02958L1.69971 13.6738L2.41071 14.3771L7.99271 8.73258L13.6372 14.3148L14.3402 13.6038Z"
              fill="#222222"
            />
          </svg>
        </Button>

        <Div padding={3} backgroundColor="blue4">
          <P
            fontSize={3}
            fontWeight={6}
            margin={0}
            marginBottom={1}
            className="body-1"
            lineHeight="copy"
            style={{ maxWidth: size.xs && !size.sm ? '250px' : 'auto' }}
          >
            {section.displayName}
          </P>

          <MarkdownBlock
            renderers={{
              paragraph: ({ children }) => (
                <P
                  fontWeight={4}
                  lineHeight="copy"
                  className="body-2"
                  fontSize={2}
                  marginBottom={1}
                >
                  <Span fontWeight={4}>{section.toolTip || ''}</Span>
                </P>
              ),
            }}
            source={section?.toolTip}
          />
        </Div>
      </div>
    </Div>
  );
};

export default ModalTooltip;
