import React, { Dispatch, SetStateAction } from 'react';
import { Divider } from '../common/Divider';
import { Col, Button, A, H5 } from '../common/Elements';
import { Div } from '../common/Elements';
import { Size } from '../common/MediaContainer';
import { Plan } from './fragments/plans.interface';

const ComparePricingMobileTabMenu: React.FC<{
  plans?: [Plan];
  selectedPlan?: Plan;
  setSelectedPlan?: Dispatch<SetStateAction<Plan | undefined>>;
  size: Size;
}> = ({ plans, selectedPlan, setSelectedPlan, size }) => {
  return (
    <Col
      display={(size.sm && !size.md) || size.xs ? 'block' : 'none'}
      flexDirection="column"
      marginBottom={3}
    >
      <Div display="flex" width="100%" justifyContent="between">
        {plans?.map((plan, i) => (
          <Button
            color="orange0"
            fontSize={3}
            border="none"
            backgroundColor="transparent"
            key={plan.contentfulId}
            fontWeight={6}
            style={{
              padding: '0 0 4px 0',
              borderBottom: `2px solid ${
                selectedPlan?.displayName === plan.displayName ? '#FF6633' : 'transparent'
              }`,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (setSelectedPlan) {
                setSelectedPlan(plan);
              }
            }}
          >
            {plan.displayName}
          </Button>
        ))}
      </Div>
    </Col>
  );
};

export default ComparePricingMobileTabMenu;
