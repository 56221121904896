// Query top nav data at page render time to accomodate for all locales
// Temp compensate for pages that are not rendered this way
//

import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../layout/layout';
import { A, Button, Row, Col, Container, Div, H1, H3, H4, Img, P, Span } from '../common/Elements';
import PlansTabMenu from './PlansTabMenu';
import { Divider } from '../common/Divider';
import { MarkdownBlock } from '../common/MarkdownBlock';
import AddonCard from './AddonCard';
import FAQAccordion from './FAQ.accordion';
import { EnablementBlade } from '../common/refresh-blades/EnablementBlade';
import TrustedByBlade from '../common/refresh-blades/TrustedByBlade';
import ComparePlans from './ComparePlans';
import { Plan, ParsedFeature, PlansPageProps, Addon } from './fragments/plans.interface';
import { FREEPLANCONTENTFULID } from './constants';
import { Icon } from '../common/Icon';
import { InterpolatedText } from '../common/InterpolatedText';
import MediaContainer from '../common/MediaContainer';
import { useTranslations } from '../../common/hooks/useTranslations';
import { getMetaTagsJSX } from '../page/page.template';

import { useLocale } from '../../common/hooks/useLocale';

export const query = graphql`
  query($locale: String!) {
    page(relativePath: { eq: "plans" }, locale: { eq: $locale }) {
      ...pageFragment
      psaBanner {
        contentfulId
        text
        learnMoreText
        learnMoreUrl
      }
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }

    trustedBy: bladeTrustedBy(
      contentfulId: { eq: "3Eyetdz3a6FNWneAIaJPUZ" }
      locale: { eq: $locale }
    ) {
      ...bladeTrustedByFragment
    }
    enablementBlade: bladeEnablement(
      contentfulId: { eq: "7AfYsI70LIeCSq0tl8kRNx" }
      locale: { eq: $locale }
    ) {
      ...bladeEnablementFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    plansData: plansPage(contentfulId: { eq: "1nq5zZRbHarzo3hoDujdXV" }, locale: { eq: $locale }) {
      contentfulId
      contentName
      heroTitle
      addOnsBladeSubtitle
      addOnsBladeTitle
      addOnsBladeDescription
      faqBladeSubtitle
      faqBladeTitle
      faqBladeDetailText
      faqBladeLinkText
      faqBladeLinkUrl
      plansDetailExpanderText
      plansDetailFeatureBreakdownText
      enablementBlade {
        copy
      }
      performance: plansDetailSectionPerformance {
        ...plansDetailSectionFragment
      }
      customization: plansDetailSectionCustomization {
        ...plansDetailSectionFragment
      }
      security: plansDetailSectionSecurity {
        ...plansDetailSectionFragment
      }
      certificateManagement: plansDetailSectionCertificateManagement {
        ...plansDetailSectionFragment
      }
      firewall: plansDetailSectionFirewall {
        ...plansDetailSectionFragment
      }
      reliability: plansDetailSectionReliability {
        ...plansDetailSectionFragment
      }
      dashboard: plansDetailSectionDashboard {
        ...plansDetailSectionFragment
      }
      insights: plansDetailSectionInsights {
        ...plansDetailSectionFragment
      }
      compliance: plansDetailSectionCompliance {
        ...plansDetailSectionFragment
      }
      support: plansDetailSectionSupport {
        ...plansDetailSectionFragment
      }

      plansDetailSectionTitlePerformance
      plansDetailSectionTitleCustomization
      plansDetailSectionTitleSecurity
      plansDetailSectionTitleCertificateManage
      plansDetailSectionTitleFirewall
      plansDetailSectionTitleReliability
      plansDetailSectionTitleDashboard
      plansDetailSectionTitleInsights
      plansDetailSectionTitleCompliance
      plansDetailSectionTitleSupport

      faqQuestionsAndAnswers {
        question
        answer
      }
      addOns {
        title
        description
        learnMoreUrl
        pricingText
        ctaText
        ctaUrl
        iconAssetFile {
          ...assetFileFragment
        }
      }

      plans {
        contentfulId
        displayName
        type
        priceCurrencySymbol
        price
        priceDurationPeriod
        billingSubtext
        description
        shortDescription
        mediumDescription
        supportResponseTitleText
        supportResponseTime
        ctaButtonUrl
        ctaButtonText
        activatedKeyFeatures
        deactivatedKeyFeatures
        additionalTermsText
        learnMoreText
        plansPageLinkUrl
        plansPageLinkText
      }
    }
    site {
      siteMetadata {
        baseURL
      }
    }
  }
`;

const parseFeatures = (plan?: Plan) => {
  const activated =
    plan?.activatedKeyFeatures.map(feature => ({
      feature,
      activated: true,
    })) || [];
  const deactivated =
    plan?.deactivatedKeyFeatures?.map(feature => ({
      feature,
      activated: false,
    })) || [];
  return [...activated, ...deactivated] as [ParsedFeature];
};

const PlansPage: FC<PlansPageProps> = ({ data, pageContext }) => {
  const plansData = data.plansData;
  const translate = useTranslations();
  const getDefaultPlan = (id?: string) => {
    return plansData.plans.find(plan => {
      if (id) {
        return plan.contentfulId === id;
      } else {
        return plan.contentfulId === FREEPLANCONTENTFULID;
      }
    });
  };

  const locale = useLocale();
  const parseInitialFeature = parseFeatures(getDefaultPlan(pageContext.planId));
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>(
    getDefaultPlan(pageContext.planId)
  );
  const [showComparison, toggleComparison] = useState(false);
  const baseURL = data.site?.siteMetadata?.baseURL || 'https://www.cloudflare.com';
  let [parsedFeatureList, setParsedFeatureList] = useState<[ParsedFeature]>(parseInitialFeature);

  useEffect(() => {
    plansData.plans.forEach((plan: Plan) => {
      if (plan.displayName === currentPlan?.displayName) {
        setCurrentPlan(plan);
      }
    });
    setParsedFeatureList(parseFeatures(currentPlan));
  }, [currentPlan]);

  // useEffect(() => {
  //   window.history.replaceState(
  //     {},
  //     document.head.title,
  //     resolveUrl(locale, pathJoin('/plans', pageContext.planId ? pageContext.planId : ''))
  //   );
  // }, [currentPlan]);
  return (
    <Layout
      pageContext={pageContext}
      topNavData={data.navData}
      topNavAbTestData={data.navAbTestData}
      footerData={data.footerData}
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
    >
      <Helmet>
        {getMetaTagsJSX(baseURL, data.page.metaTags)}
        {data.page.noindex && <meta name="robots" content="noindex, nofollow"></meta>}
      </Helmet>
      <Div className="br-refresh">
        <>
          <Div
            backgroundColor={'blue4'}
            display={'flex'}
            alignItems={'center'}
            paddingTop={[4, 9]}
            paddingBottom={[7, 10]}
          >
            <Container>
              <Row>
                <Col lg={8}>
                  <H1 textAlign="left">{plansData.heroTitle}</H1>
                </Col>
              </Row>
            </Container>
          </Div>

          <Div backgroundColor={'blue4'}>
            <Container>
              <PlansTabMenu
                setPlan={setCurrentPlan}
                plans={data.plansData.plans}
                currentPlan={currentPlan}
                size="large"
              />
            </Container>
          </Div>
          <Div backgroundColor="transparent">
            <Container>
              <PlansTabMenu
                setPlan={setCurrentPlan}
                plans={data.plansData.plans}
                currentPlan={currentPlan}
                size="small"
              />
            </Container>
          </Div>
          <MediaContainer>
            {size => (
              <Div
                display={['block', 'block']}
                marginHorizontal={[3, 0, 0, 0]}
                marginTop={[3, 0, 0, 0]}
                marginBottom={[6, 0, 0, 0]}
                lineHeight="copy"
                className={`${(size.sm && !size.md) || size.xs ? 'container pt3' : ''} mx-auto`}
              >
                <Div
                  display="flex"
                  color="white"
                  alignItems="start"
                  justifyContent="center"
                  backgroundColor="blue1"
                  flexDirection={['column', 'row']}
                  fontSize={2}
                  paddingVertical={1}
                  paddingHorizontal={[2, 0, 0, 0]}
                >
                  <Span className={`${size.sm && !size.xs && !size.md ? 'ph3' : ''} mx-auto`}>
                    <Span marginRight={1}>
                      {data.page.psaBanner?.text}

                      <A
                        href={data.page.psaBanner?.learnMoreUrl}
                        display={['inline', 'none']}
                        textAlign="left"
                        alignItems="center"
                        hovered={{ color: 'blue5' }}
                        color="white"
                        fontWeight={6}
                        fontSize={2}
                      >
                        {data.page.psaBanner?.learnMoreText} <Icon className="ml1" type="chevron" />
                      </A>
                    </Span>
                    <A
                      href={data.page.psaBanner?.learnMoreUrl}
                      display={['none', 'inline-flex']}
                      textAlign="left"
                      alignItems="center"
                      hovered={{ color: 'blue5' }}
                      color="white"
                      fontWeight={6}
                      fontSize={2}
                    >
                      {data.page.psaBanner?.learnMoreText} <Icon className="ml1" type="chevron" />
                    </A>
                  </Span>
                </Div>
                <Div
                  className="divider container"
                  color="gray2"
                  border="top"
                  marginTop={[4, 7]}
                  marginBottom={3}
                  width="100%"
                ></Div>
              </Div>
            )}
          </MediaContainer>

          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Div
                  display="flex"
                  flexDirection="column"
                  justifyContent="between"
                  style={{ height: '100%' }}
                >
                  <Div style={{ flexGrow: 1 }}>
                    <Div width="100%">
                      <H3 color="orange0">{currentPlan?.displayName}</H3>
                      <Div
                        className="divider"
                        color="gray2"
                        border="top"
                        marginVertical={2}
                        width="100%"
                      ></Div>
                    </Div>
                    <H4 margin={0} fontWeight={6}>
                      {currentPlan?.priceCurrencySymbol === null
                        ? currentPlan?.price
                        : `${currentPlan?.priceCurrencySymbol}${currentPlan?.price}/${currentPlan?.priceDurationPeriod}`}
                    </H4>
                    <P fontSize={1} color="gray0" marginBottom={3} marginTop={1}>
                      {currentPlan?.billingSubtext}
                    </P>

                    <Div fontSize={2} lineHeight="copy" fontWeight={4} color="black">
                      {currentPlan?.description}
                    </Div>
                  </Div>
                  <Div>
                    <Div fontSize={2} marginTop={6}>
                      <MarkdownBlock
                        renderers={{
                          paragraph: ({ children }) => (
                            <P
                              fontWeight={4}
                              lineHeight="copy"
                              color="black"
                              fontSize={2}
                              marginBottom={0}
                            >
                              <Span fontWeight={6}>{`${translate(
                                'supportResponseTitleText'
                              )} `}</Span>
                              {children}
                            </P>
                          ),
                        }}
                        source={currentPlan?.supportResponseTime}
                      />
                    </Div>

                    <Div
                      className="divider "
                      color="gray2"
                      border="top"
                      marginVertical={3}
                      width="100%"
                    ></Div>

                    <A
                      backgroundColor="orange1"
                      color="white"
                      border="none"
                      paddingVertical={2}
                      textAlign="center"
                      marginBottom={1}
                      className="button-cta ls2 tc bw2 auto-btn  mr4-l"
                      fontSize={2}
                      fontWeight={7}
                      paddingHorizontal={3}
                      width="100%"
                      href={currentPlan?.ctaButtonUrl}
                    >
                      {currentPlan?.ctaButtonText}
                    </A>
                    <Div fontSize={1} fontWeight={4} color="gray0">
                      {translate('additionalTermsText')}
                    </Div>
                  </Div>
                </Div>
              </Col>
              <Col display={['block', 'none']}>
                <Div
                  className="divider "
                  color="gray2"
                  border="top"
                  marginVertical={3}
                  width="100%"
                ></Div>
              </Col>
              <Col lg={1} display={['none', 'none', 'none', 'block']} />
              <Col lg={4} md={4}>
                <Div
                  position="relative"
                  display="flex"
                  justifyContent="between"
                  flexDirection="column"
                >
                  <Div>
                    {parsedFeatureList?.slice(0, 9).map(item => (
                      <Div key={item.feature} display="flex" alignItems="baseline" marginBottom={3}>
                        {item.activated ? (
                          <>
                            <Span lineHeight={0} style={{ flexShrink: 0 }}>
                              <Icon type="checkmark" className="mr2 orange0 " />
                            </Span>

                            <Span fontSize={2} color="black" fontWeight={4}>
                              <InterpolatedText text={item.feature} />
                            </Span>
                          </>
                        ) : (
                          <>
                            <Span lineHeight={0} style={{ flexShrink: 0 }}>
                              <Icon className="gray2 mr2" type="markX" />
                            </Span>

                            <Span fontSize={2} color="gray1" fontWeight={4} lineHeight="copy">
                              <InterpolatedText text={item.feature} />
                            </Span>
                          </>
                        )}
                      </Div>
                    ))}

                    <Div
                      alignItems="center"
                      display={['none', 'block']}
                      fontSize={3}
                      marginTop={[1, 9]}
                    >
                      <Span fontWeight={6} marginRight={1} color="black">
                        {currentPlan?.learnMoreText || ''}
                      </Span>
                      <A
                        href={currentPlan?.plansPageLinkUrl}
                        className="learn-more"
                        color="blue1"
                        fontWeight={7}
                        fontSize={3}
                      >
                        {currentPlan?.plansPageLinkText}
                      </A>
                    </Div>
                  </Div>
                </Div>
              </Col>
              <Col lg={4} md={4}>
                {parsedFeatureList?.slice(9, parsedFeatureList.length).map(item => (
                  <Div key={item.feature} display="flex" alignItems="baseline" marginBottom={3}>
                    {item.activated ? (
                      <>
                        <Span lineHeight={0} style={{ flexShrink: 0 }}>
                          <Icon type="checkmark" className="mr2 orange0" />
                        </Span>
                        <Span fontSize={2} color="black" fontWeight={4}>
                          <InterpolatedText text={item.feature} />
                        </Span>
                      </>
                    ) : (
                      <>
                        <Span lineHeight={0} style={{ flexShrink: 0 }}>
                          <Icon className="gray2 mr2 " type="markX" />
                        </Span>
                        <Span fontSize={2} color="gray1" fontWeight={4} lineHeight="copy">
                          <InterpolatedText text={item.feature} />
                        </Span>
                      </>
                    )}
                  </Div>
                ))}
              </Col>
            </Row>

            <Row noGutters display={['block', 'none']}>
              <Col lg={3} md={3}></Col>
              <Col lg={1} md={1}></Col>
              <Col lg={8} md={8}>
                <Div
                  alignItems="center"
                  fontSize={3}
                  marginTop={[1, 0]}
                  display={['block', 'none']}
                >
                  <Span fontWeight={6} marginRight={1} color="black">
                    {currentPlan?.learnMoreText || ''}
                  </Span>
                  <A
                    href={currentPlan?.plansPageLinkUrl}
                    className="learn-more"
                    color="blue1"
                    fontWeight={7}
                    fontSize={3}
                  >
                    {currentPlan?.plansPageLinkText}
                  </A>
                </Div>
              </Col>
            </Row>

            <Div
              className="divider"
              color="gray2"
              border="top"
              marginVertical={3}
              width="100%"
            ></Div>

            <Row paddingBottom={9}>
              <Col lg={5} md={6} sm={12}>
                <Button
                  fontWeight="bold"
                  color="blue1"
                  backgroundColor="white"
                  border="all"
                  borderWidth={2}
                  paddingVertical={2}
                  paddingHorizontal={4}
                  borderColor="blue1"
                  fontSize={2}
                  className="button-cta auto-btn pointer"
                  wrap="none"
                  onClick={() => toggleComparison(!showComparison)}
                >
                  {plansData.plansDetailExpanderText}
                </Button>
              </Col>
            </Row>

            <Div style={{ display: showComparison ? 'block' : 'none' }} paddingBottom={10}>
              <ComparePlans
                plansData={plansData}
                selectedPlan={currentPlan}
                setSelectedPlan={setCurrentPlan}
              />
            </Div>
          </Container>
          <Div backgroundColor="blue5">
            <Container>
              <Row paddingTop={9}>
                <Col lg={6}>
                  <Span
                    display="block"
                    color="orange0"
                    textTransform="uppercase"
                    fontSize={2}
                    fontWeight={7}
                    marginBottom={1}
                    lineHeight="copy"
                  >
                    {plansData?.addOnsBladeSubtitle}
                  </Span>
                  <H3 fontWeight={6} marginBottom={2}>
                    {plansData?.addOnsBladeTitle}
                  </H3>
                  <P className="body-1" marginBottom={[6, 7]}>
                    {plansData?.addOnsBladeDescription}
                  </P>
                </Col>
              </Row>
              <Row paddingBottom={10}>
                {plansData?.addOns.map((addOn: Addon) => (
                  <Col lg={4} md={6} key={addOn.title}>
                    <AddonCard addon={addOn} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Div>
          <Container>
            <Row paddingTop={9} paddingBottom={10}>
              <Col lg={12}>
                <Span
                  display={'block'}
                  color="orange0"
                  fontSize={2}
                  fontWeight={7}
                  marginBottom={1}
                  textTransform="uppercase"
                  lineHeight="copy"
                >
                  {plansData?.faqBladeSubtitle}
                </Span>
              </Col>
              <Col lg={6}>
                <H3 fontWeight={6} marginBottom={5}>
                  {plansData?.faqBladeTitle}
                </H3>
              </Col>
              <Col lg={12}>
                <FAQAccordion questions={plansData?.faqQuestionsAndAnswers} />
                <Div>
                  <Span fontSize={3} fontWeight={4} marginRight={1} color="black">
                    {plansData.faqBladeDetailText}
                  </Span>
                  <A
                    href={plansData.faqBladeLinkUrl}
                    className="learn-more"
                    fontSize={3}
                    fontWeight={7}
                    color="blue1"
                  >
                    {plansData.faqBladeLinkText}
                  </A>
                </Div>
              </Col>
            </Row>
          </Container>

          {data.trustedBy && <TrustedByBlade blade={data.trustedBy} />}

          {data.enablementBlade && (
            <EnablementBlade blade={data.enablementBlade} page={data.page}></EnablementBlade>
          )}
        </>
      </Div>
    </Layout>
  );
};

export default PlansPage;
