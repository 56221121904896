import React, { FC } from 'react';
import { Span } from '../common/Elements';
import { Icon } from '../common/Icon';

const AvailabilityIcon: FC<{ available: boolean; text: string }> = ({ available, text }) => {
  return (
    <>
      {available ? (
        <Span lineHeight={0} style={{ flexShrink: 0 }}>
          <Icon type="checkmark" className="mr1 orange0" />
        </Span>
      ) : text ? (
        <Span fontWeight={6} fontSize={2}>
          {text}
        </Span>
      ) : (
        <Span lineHeight={0} style={{ flexShrink: 0 }}>
          <Icon type="markX" className="mr1 gray2" />
        </Span>
      )}
    </>
  );
};

export default AvailabilityIcon;
