import React, { FC } from 'react';
import { ContentfulAssetFile } from '../common/ContentfulAssetFile/ContentfulAssetFile';
import { Div, H5, P, A, Span } from '../common/Elements';
import { MarkdownBlock } from '../common/MarkdownBlock';
import { Addon } from './fragments/plans.interface';

interface AddonCardProps {
  addon: Addon;
}
const PlanCard: FC<AddonCardProps> = ({ addon }) => {
  return (
    <Div
      border="all"
      paddingTop={3}
      paddingHorizontal={[3, 4]}
      display={'flex'}
      flexDirection="column"
      alignItems="start"
      justifyContent="between"
      marginBottom={4}
      borderColor="gray2"
    >
      <ContentfulAssetFile assetFile={addon.iconAssetFile} />
      <H5 fontWeight={6} marginVertical={2}>
        {addon.title}
      </H5>

      <MarkdownBlock
        renderers={{
          paragraph: ({ children }) => (
            <P
              marginBottom={4}
              marginTop={0}
              style={{ height: '170px', overflow: 'hidden' }}
              className="body-2"
            >
              {children}
            </P>
          ),
        }}
        source={addon.description}
      />

      <A
        href={addon.learnMoreUrl}
        border="none"
        className="learn-more"
        display="flex"
        fontSize={3}
        alignItems="center"
      >
        <Span marginRight={0} color="blue1" fontWeight={7}>
          Learn More
        </Span>
      </A>
      <>
        <Div className="divider" color="gray2" border="top" marginVertical={2} width="100%"></Div>
        <P lineHeight="copy" marginBottom={2} marginTop={0} fontSize={2} fontWeight={6}>
          {addon.pricingText}
        </P>
        <A
          fontWeight="bold"
          backgroundColor="blue1"
          color="white"
          border="none"
          paddingVertical={2}
          marginTop={0}
          marginBottom={3}
          className="pointer dim"
          padding={2}
          href={addon.ctaUrl}
          lineHeight="copy"
          display="inline-block"
          textAlign="center"
        >
          {addon.ctaText}
        </A>
      </>
    </Div>
  );
};

export default PlanCard;
