export const PERFORMANCE = 'Performance';
export const CUSTOMIZATION = 'Customization & Optimization';
export const SECURITY = 'Security';
export const CERTIFICATEMANAGEMENT = 'Certificate Management';
export const FIREWALL = 'Firewall';
export const RELIABILITY = 'Reliability';
export const DASHBOARD = 'Dashboard, User and API Access';
export const INSIGHT = 'Insights & Analytics';
export const COMPLIANCE = 'Compliance';
export const SUPPORT = 'Support';

export const FREEPLANCONTENTFULID = '2Q6x3NuTbPWWTBDSmnJlaH';
export const PROPLANCONTENTFULID = '5BfU1DjHLYkKc2Glx3m1Mk';
export const BUSINESSPLANCONTENTFULID = '64lgMbnOMKjkBseoc8H4f2';
export const ENTERPRISEPLANCONTENTFULID = '3E2emldchv1o8gkddkwxiS';
