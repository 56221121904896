import {
  PERFORMANCE,
  CUSTOMIZATION,
  SECURITY,
  CERTIFICATEMANAGEMENT,
  FIREWALL,
  RELIABILITY,
  DASHBOARD,
  INSIGHT,
  COMPLIANCE,
  SUPPORT,
} from './constants';
import { PlansData } from './fragments/plans.interface';

interface SelectedSection {
  english: string;
  localized: string;
}

export function getPlanDetailsSection(plansData?: PlansData, selectedSection?: SelectedSection) {
  const english = selectedSection?.english;

  const plansDetailsSectionMap = new Map([
    [PERFORMANCE, plansData?.performance],
    [CUSTOMIZATION, plansData?.customization],
    [SECURITY, plansData?.security],
    [CERTIFICATEMANAGEMENT, plansData?.certificateManagement],
    [FIREWALL, plansData?.firewall],
    [RELIABILITY, plansData?.reliability],
    [DASHBOARD, plansData?.dashboard],
    [INSIGHT, plansData?.insights],
    [COMPLIANCE, plansData?.compliance],
    [SUPPORT, plansData?.support],
  ]);

  return plansDetailsSectionMap.get(english || 'Performance');
}
