import React, { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ReactMarkdown from 'react-markdown';
import { Span } from '../common/Elements';
import { Icon } from '../common/Icon';
import { QuestionAnswer } from './fragments/plans.interface';

const FAQAccordion: FC<{ questions: [QuestionAnswer] }> = ({ questions }) => {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', e => {
      setMouseDown(true);
    });
    document.addEventListener('keydown', e => {
      setMouseDown(false);
    });
  }, []);
  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="guidelist pb6-l pb3"
    >
      {questions.map((item: QuestionAnswer, index: number) => (
        <AccordionItem className="guidelist__item pv3" key={index + 1}>
          <AccordionItemHeading>
            <AccordionItemButton
              className={`pointer guidelist__button flex justify-between   ${
                mouseDown ? 'button-outline' : ''
              }`}
            >
              <Span className="body-1 fw6">{item.question}</Span>
              <Span className="dropdown-button-plans flex items-center"></Span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ReactMarkdown
              source={item.answer}
              escapeHtml={false}
              renderers={{
                paragraph: ({ children }) => (
                  <p className="ma0 lh-copy black mt2  pr5">{children}</p>
                ),
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FAQAccordion;
