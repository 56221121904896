import React from 'react';
import { Col } from '../common/Elements';
import { Size } from '../common/MediaContainer';
import AvailabilityIcon from './AvailabilityIcon';
import {
  BUSINESSPLANCONTENTFULID,
  ENTERPRISEPLANCONTENTFULID,
  FREEPLANCONTENTFULID,
  PROPLANCONTENTFULID,
} from './constants';
import { Plan, PlansDetailSection } from './fragments/plans.interface';

const FeaturesIcons: React.FC<{ section: PlansDetailSection; size: Size; selectedPlan?: Plan }> = ({
  section,
  size,
  selectedPlan,
}) => {
  return (
    <>
      <Col
        lg={2}
        md={3}
        sm={3}
        marginBottom={size.md && size.lg ? 0 : 2}
        justifyContent="between"
        alignItems="center"
        display={
          (size.sm && !size.md && selectedPlan?.contentfulId === FREEPLANCONTENTFULID) ||
          (size.xs && selectedPlan?.contentfulId === FREEPLANCONTENTFULID) ||
          size.md
            ? 'flex'
            : 'none'
        }
      >
        <AvailabilityIcon available={section.freeDetailOnOff} text={section.freeDetailText} />
      </Col>
      <Col
        display={
          (size.sm && !size.md && selectedPlan?.contentfulId === PROPLANCONTENTFULID) ||
          (size.xs && selectedPlan?.contentfulId === PROPLANCONTENTFULID) ||
          size.md
            ? 'flex'
            : 'none'
        }
        lg={2}
        md={3}
        sm={3}
        marginBottom={size.md && size.lg ? 0 : 3}
        justifyContent="between"
        alignItems="center"
      >
        <AvailabilityIcon available={section.proDetailOnOff} text={section.proDetailText} />
      </Col>
      <Col
        display={
          (size.sm && !size.md && selectedPlan?.contentfulId === BUSINESSPLANCONTENTFULID) ||
          (size.xs && selectedPlan?.contentfulId === BUSINESSPLANCONTENTFULID) ||
          size.md
            ? 'flex'
            : 'none'
        }
        lg={2}
        md={3}
        sm={3}
        marginBottom={size.md && size.lg ? 0 : 3}
        justifyContent="between"
        alignItems="center"
      >
        <AvailabilityIcon available={section.bizDetailOnOff} text={section.bizDetailText} />
      </Col>
      <Col
        display={
          (size.sm && !size.md && selectedPlan?.contentfulId === ENTERPRISEPLANCONTENTFULID) ||
          (size.xs && selectedPlan?.contentfulId === ENTERPRISEPLANCONTENTFULID) ||
          size.md
            ? 'flex'
            : 'none'
        }
        lg={2}
        md={3}
        sm={3}
        marginBottom={size.md && size.lg ? 0 : 3}
        justifyContent="between"
        alignItems="center"
      >
        <AvailabilityIcon
          available={section.enterpriseDetailOnOff}
          text={section.enterpriseDetailText}
        />
      </Col>
    </>
  );
};

export default FeaturesIcons;
